define("discourse/plugins/discourse-silenced-flair/initializers/discourse-silenced-flair", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-silenced-flair",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        api.includePostAttributes("silenced");
        const h = require("virtual-dom").h;
        const currentUser = api.getCurrentUser();
        if (!currentUser) {
          return;
        }
        api.createWidget("avatar-flair-silenced", {
          tagName: "div.avatar-flair-silenced",
          html() {
            return h("span", (0, _iconLibrary.iconNode)("microphone-slash", {
              class: "avatar-flair-silenced__icon",
              title: "Silenced"
            }));
          }
        });
        api.decorateWidget("post-avatar:after", helper => {
          const user = helper.getModel();
          if (user && user.get("silenced")) {
            return helper.widget.attach("avatar-flair-silenced");
          }
        });
      });
    }
  };
});