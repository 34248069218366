define("discourse/plugins/discourse-silenced-flair/discourse/templates/connectors/user-card-avatar-flair/user-card-avatar-flair-silenced", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.user.silenced}}
    <div class="avatar-flair-silenced">
      <span title="Silenced">
        {{d-icon "microphone-slash"}}
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "HZnC7m5o",
    "block": "[[[41,[30,1,[\"user\",\"silenced\"]],[[[1,\"  \"],[10,0],[14,0,\"avatar-flair-silenced\"],[12],[1,\"\\n    \"],[10,1],[14,\"title\",\"Silenced\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"microphone-slash\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-silenced-flair/discourse/templates/connectors/user-card-avatar-flair/user-card-avatar-flair-silenced.hbs",
    "isStrictMode": false
  });
});